@use 'dawuti.config' as dawuti;
@use 'theme/mixins' as mixins;

.cdk-overlay-pane.mat-datepicker-popup {
  @include dawuti.mq-xs(down) {
    width: 100%;
    right: 0 !important;
    left: 0 !important;
  }
}

.mat-datepicker-content {
  background-color: var(--color-light);
  background-color: var(--color-primary-90);
  padding: calc(var(--s) * 2);
  width: 100%;
  box-shadow: none;

  @include dawuti.mq-xs {
    width: 375px;
    @include mixins.shadow-elevation-5;
  }

  .mat-calendar {
    width: 100% !important;
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-body-label {
    opacity: 0;
    padding: 0 !important;
  }

  .mat-calendar-table-header {
    th {
      letter-spacing: 0.1px;
      opacity: 0.4;
      font-weight: 500;
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
  }

  .mat-calendar-body-cell {
    .mat-calendar-body-selected {
      background-color: var(--color-primary);
      color: var(--color-light);
    }
    .mat-calendar-body-selected.mat-calendar-body-today {
      background-color: var(--color-primary);
      color: var(--color-light);
    }

    &.is-highlighted {
      .mat-calendar-body-cell-content {
        background-color: var(--color-primary-90);
        color: var(--color-primary);
      }
      .mat-calendar-body-cell-content.mat-calendar-body-selected {
        background-color: var(--color-primary);
        color: var(--color-light);
      }
    }
  }

  .mat-calendar-body-cell {
    &.is-highlighted {
      .mat-calendar-body-cell-content {
        background-color: var(--color-light);
        color: var(--color-primary);
        &.mat-calendar-body-today {
          border: 2px solid var(--color-primary);
        }
        &.mat-calendar-body-today.mat-calendar-body-selected {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  .mat-calendar-body-cell-content {
    border: none;
    font-weight: 500;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);

    &.mat-calendar-body-today {
      background-color: var(--color-primary);
      color: var(--color-light);
    }
  }
}
