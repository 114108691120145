/* You can add global styles to this file, and also import other style files */

@forward './theme/fonts';
@forward './theme/animations';
@forward './theme/variables';
@forward './theme/material';
@forward './theme/commons';
@forward './theme/typography';
@forward './theme/buttons';
@forward './theme/alerts';
@forward './theme/dialogs';
@forward './theme/icons';
@forward './theme/page';
@forward './theme/datepicker';
@forward './theme/form-fields';
@forward './theme/mixins';
@forward 'swiper/scss';