@use 'dawuti.config' as dawuti;
// TODO Refactor main styles

.app-wrapper {
  display: flex;
  flex-grow: 0;
  min-height: 100vh;
}
.page-wrapper {
  width: 100%;
}

.page {
  padding-top: var(--page-padding-top);
  padding-bottom: var(--page-padding-bottom);

  display: flex;
  flex-direction: column;

  &.page--interior {
    padding-top: 0;
  }

  .header-page {
    p {
      margin-top: calc(var(--s) / 2);
    }
  }

  section {
    margin-top: calc(var(--s) * 3);

    @include dawuti.mq-md {
      margin-top: calc(var(--s) * 3);
    }

    > header {
      margin-bottom: calc(var(--s) * 3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 412px;

      .button {
        white-space: nowrap;
        margin-left: calc(var(--s));
      }
    }
  }
}

nai-toolbar-top + .page {
  padding-top: 0;
}

.tabs {
  padding: 0 var(--safe-area) calc(var(--s) * 1.5) var(--safe-area);
  border-bottom: var(--card-border);
  display: flex;
  overflow-x: auto;
  .button {
    white-space: nowrap;
  }
  > button + button {
    margin-left: calc(var(--s) * 1.5);
  }
}
