mat-form-field {
  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: var(--color-primary-95);
      border-radius: var(--form-field-border-radius);
      .mat-form-field-infix {
        top: -10px;
      }

      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
      textarea {
        min-height: 200px;
      }
    }

    .mat-form-field-label {
      color: var(--color-neutral-variant-30);
    }
    .mat-form-field-wrapper {
      margin-bottom: 10px;
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin-top: 0;
    }
    .mat-form-field-flex {
      border-radius: var(--form-field-border-radius);
      height: calc(var(--s) * 7.5);
      .mat-form-field-infix {
        top: -5px;
      }
      .mat-form-field-outline {
        border-radius: calc(var(--s) / 2);
        color: var(--color-neutral-variant-50);
      }
      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
      textarea {
        min-height: 200px;
      }
    }

    .mat-form-field-label {
      color: var(--color-neutral-variant-30);
    }

    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        margin-bottom: var(--s);
      }
    }
    &:not(.mat-form-field-invalid) {
      .mat-form-field-wrapper {
        padding-bottom: calc(var(--s) * 1.25);
      }
    }
  }
}

fieldset {
  border: 0;
  padding: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  // text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-hidden-wrapper {
  input {
    position: absolute;
    opacity: 0;
  }
}

.input {
  border: none;
  border-radius: var(--form-field-border-radius);
  border-bottom: 1px solid black;
  background-color: var(--color-primary-95);
  color: var(--color-text-variant);
  min-height: calc(var(--s) * 7);
  &.input--input-hidden {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}
