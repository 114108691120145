@use '@angular/material' as mat;
@include mat.core();

$material-primary: (
  50: #e3ecf4,
  100: #b8cfe4,
  200: #89b0d2,
  300: #5990bf,
  400: #3678b2,
  500: #1260a4,
  600: #10589c,
  700: #0d4e92,
  800: #0a4489,
  900: #053378,
  A100: #a7c4ff,
  A200: #74a2ff,
  A400: #4181ff,
  A700: #2770ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$material-accent: (
  50: #ffefe5,
  100: #ffd7bd,
  200: #ffbd91,
  300: #ffa365,
  400: #ff8f44,
  500: #ff7b23,
  600: #ff731f,
  700: #ff681a,
  800: #ff5e15,
  900: #ff4b0c,
  A100: #ffffff,
  A200: #fff8f6,
  A400: #ffd0c3,
  A700: #ffbca9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($material-primary);
$my-accent: mat.define-palette($material-accent);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $material-primary,
      accent: $material-accent,
    ),
  )
);

@include mat.all-component-themes($my-theme);

mat-checkbox.mat-primary.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: var(--color-text);
}

mat-checkbox.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--color-tertiary-40);
}

.mat-checkbox-label {
  white-space: break-spaces;
}
.mat-checkbox-layout {
  align-items: flex-start !important;
  // padding-top: calc(var(--s) * 1.5);
  .mat-checkbox-inner-container {
    margin-top: calc(var(--s) / 2);
    margin-bottom: calc(var(--s) / 2);
    margin-right: calc(var(--s) * 1.5);
  }
}

//PAGINATOR

.mat-paginator-container {
  justify-content: center !important;
  .mat-button-base {
    span {
      color: var(--color-text) !important;
    }
    &.mat-button-disabled {
      opacity: 0.3;
    }
  }
  .mat-paginator-page-size {
    display: none !important;
  }

  .mat-paginator-range-actions {
    .mat-paginator-range-label {
      font-size: 14px;
      order: 1;
    }
    > button {
      &:first-child {
        order: 0;
      }
      &:last-child {
        order: 1;
      }
    }
  }
}

// SNACKBAR
.mat-snack-bar-container {
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

// RADIO BUTTONS

mat-radio-button .mat-radio-inner-circle {
  background-color: var(--color-text);
}
mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-text);
}
mat-radio-button .mat-radio-outer-circle {
  border-color: var(--color-neutral-variant);
}

// PROGRESS BAR
.mat-progress-bar {
  height: var(--s) !important;
}
.mat-progress-bar-background {
  fill: var(--color-neutral-variant-95);
}
.mat-progress-bar-buffer {
  background-color: var(--color-neutral-variant-95);
}
.mat-progress-bar-fill::after {
  background-color: var(--color-primary);
}
